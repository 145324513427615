// event.selectors.ts

import { CurrentState } from '@gth-legacy/components/event-info/state/state';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { selectUser } from '@sentinels/state/features/auth/selectors';
import { EventState } from '@sentinels/state/features/events/state';
import { userLoadOne } from '@sentinels/state/features/user/actions';
import { selectUserByUid } from '@sentinels/state/features/user/selectors';

export const selectEventState = createFeatureSelector<EventState>('event');

export const selectEventByid = (id: string) =>
  createSelector(
    selectEventState,
    (state: EventState) => {
      return state.events[id];
    },
  );

export const selectEventsByUids = (ids: string[]) =>
  createSelector(
    selectEventState,
    (state: EventState) => ids.map((id) => state.events[id]).filter((event) => !!event),
  );

export const selectAllEvents = createSelector(
  selectEventState,
  (state) => {
    return Object.values(state.events);
  },
);

export const getProfileEventsState = createSelector(
  selectEventState,
  (state) => {
    return state.profileEventsStatus;
  },
);

export const selectEventsByCreatorId = createSelector(
  selectAllEvents,
  selectUser,
  (events, user) => events
    .filter((event) => event.creatorId === user.uid),
);


export const selectJoinerObj = createSelector(
  selectEventState,
  (state: EventState) => state.joiners,
);

export const selectEventsByEventIds = (eventIds: string[]) =>
  createSelector(
    selectEventState,
    (state: EventState) =>
      eventIds.map((eventId) =>
        state.events[eventId]).filter((event) => !!event),
  );

export const selectEventsIdsPlayerAsJoiner =
  (creatorId: string) => createSelector(
    selectJoinerObj,
    (joiners) => {
      const eventIdsWithPlayerJoiner = Object.keys(joiners).filter((eventId) => {
        const eventJoiners = joiners[eventId];
        return eventJoiners.some((joiner) => joiner.player === creatorId);
      });
      return eventIdsWithPlayerJoiner;
    },
  );

export const selectEventsWithPlayerAsJoiner = (creatorId: string) => createSelector(
  selectEventsIdsPlayerAsJoiner(creatorId),
  selectAllEvents,
  (eventIds, allEvents) => {
    const events = eventIds.map((id) => selectEventByid(id)(allEvents));
    return events.filter((event) => !!event);
  },
);

export const selectEventsByCreatorIdAndPlayerAsJoiner = (
  creatorId: string,
  isOnlyUpcomingEvents = true,
) =>
  createSelector(
    selectAllEvents,
    selectUserByUid(creatorId),
    selectEventsIdsPlayerAsJoiner(creatorId),
    (events, user, eventIdsWithPlayerJoiner) => {
      if (!user) return [];
      const today = new Date();
      events = events
        .filter((event) => event.creatorId === user.uid ||
          eventIdsWithPlayerJoiner.includes(event.id))
        .filter((e) => !e.cancelled);
      if (isOnlyUpcomingEvents) {
        events = events.filter((e) => e.dateStart && e.dateStart.getTime() > today.getTime());
      }
      return events
        .sort((a, b) => a.dateStart.getTime() - b.dateStart.getTime());
    },
  );
